const binding = { modules: {}, dataActions: {} };

    (binding.modules['peruvian'] = {
        c: () => require('partner/themes/peruvian/peruvian.tsx'),
        $type: 'themeModule',
        da: [],
        definitionExtensions: ['account-generic-tile','account-welcome-tile','cart-icon','cart','checkout-delivery-options','checkout-guest-profile','checkout','content-block','header','navigation-menu','promo-banner','search-result-container','text-block'],
        iNM: false,
        ns: '__local__',
        n: 'peruvian',
        p: '__local__',
        
        pdp: '',
        
        themeSettings: 'peruvian.theme.settings.json',
        md: 'src/themes/peruvian'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };